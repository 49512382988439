import { Cloud$OAuthToken } from '@otovo/shared/types/cloudApi';
import Oauth2 from 'client-oauth2';
import { getTokenFromStore, saveTokenToStore } from './authStorage';

let oAuthToken: Oauth2.Token;
let oauthClient: Oauth2;

function getClient() {
  if (!oauthClient) {
    oauthClient = new Oauth2({
      clientId: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID,
      accessTokenUri: process.env.NEXT_PUBLIC_OAUTH_TOKEN_ENDPOINT,
      authorizationUri: process.env.NEXT_PUBLIC_OAUTH_AUTHORIZATION_ENDPOINT,
      // @ts-expect-error 2345
      authorizationGrants: ['credentials'],
    });
  }
  return oauthClient;
}

export const validToken = (): boolean => !!oAuthToken && !oAuthToken.expired();

const getTokenExpireTime = (tokenExpiresIn, tokenReceived) => {
  if (!tokenExpiresIn || !tokenReceived) {
    return -1;
  }

  const now = new Date();
  const expireDate = new Date(tokenReceived);
  expireDate.setSeconds(expireDate.getSeconds() + tokenExpiresIn);

  return expireDate.getTime() - now.getTime();
};

export const getToken = () => {
  if (!__isBrowser__) {
    throw new Error(
      'Implementation not thread safe - unable to use OAuth token for server side rendered content.',
    );
  }

  if (oAuthToken) {
    return oAuthToken;
  }

  throw new Error('no token');
};

export const setToken = (token: Cloud$OAuthToken) => {
  if (!__isBrowser__) {
    throw new Error(
      'Implementation not thread safe - unable to use OAuth token for server side rendered content.',
    );
  }

  const newToken = getClient().createToken(
    token.access_token,
    token.refresh_token,
    'bearer',
    null,
  );

  oAuthToken = newToken;

  const expireTime = getTokenExpireTime(token.expires_in, token.received);
  oAuthToken.expiresIn(expireTime);

  saveTokenToStore(token, expireTime);
};

export function loadAuthTokenFromStorage() {
  const token = getTokenFromStore();
  if (token) {
    setToken(token);
  }
}

export function clearOauthToken() {
  oAuthToken = null;
}
