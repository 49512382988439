import Head from '@otovo/shared/components/Head/Head';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import resolveOpenGraphImage from '@otovo/shared/utils/resolveOpenGraphImage';
import { useIntl } from 'react-intl';
import m from './messages';

const MyPageHead = () => {
  const { BU_CONFIG } = useGlobalConfig();
  const intl = useIntl();
  return (
    <Head
      image={resolveOpenGraphImage(BU_CONFIG.type)}
      title={intl.formatMessage(m.myPageTitle)}
    >
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={BU_CONFIG.favicon}
        key={`favicon-${BU_CONFIG.company_name}`}
      />
    </Head>
  );
};

export default MyPageHead;
