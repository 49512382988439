import storage from '@otovo/shared/lib/localStorage';
import { Cloud$OAuthToken } from '@otovo/shared/types/cloudApi';

export function saveTokenToStore(token: Cloud$OAuthToken, expireTime: number) {
  storage.setItem('token', token, expireTime * 1000);
}

export function getTokenFromStore(): Cloud$OAuthToken | void {
  return storage.getItem('token');
}

export function removeTokenFromStore() {
  storage.removeItem('token');
}
