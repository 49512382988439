import { Box } from '@otovo/rainbow';
import Footer from '@otovo/shared/Footer';
import ErrorBoundary from '@otovo/shared/components/ErrorBoundary/ErrorBoundary';
import dynamic from 'next/dynamic';

import use100vh from '@otovo/shared/hooks/use100vh';

const MyPageNav = dynamic(() => import('./MyPageNav'));

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
  footer?: React.ReactNode;
  navbar?: React.ReactNode;
};

const MyPagePage = (props: Props) => {
  const { children, footer = <Footer />, navbar, ...rest } = props;

  return (
    <>
      <link
        rel="stylesheet"
        href="https://otovoweb.s3.eu-central-1.amazonaws.com/storefront-global-styles-workaround.css"
      />
      <div style={{ height: use100vh() }} className="relative flex flex-col">
        {navbar || <MyPageNav />}
        <ErrorBoundary name="Top level ErrorBoundary">
          <Box {...rest} as="main">
            {children}
          </Box>
        </ErrorBoundary>
        {footer}
      </div>
    </>
  );
};

export default MyPagePage;
