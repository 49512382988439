import Oauth2 from 'client-oauth2';
import { getToken } from './auth/auth';

type RequestData = {
  method: string;
  headers: HeadersInit;
  body?: any;
};

type Args = {
  token?: Oauth2.Token;
  url: string;
  requestData: RequestData;
};

function signPayload({ url, requestData, token }: Args) {
  const { headers } = requestData;

  // Narrow headers to Record<string, string>
  if (Array.isArray(headers) || headers instanceof Headers) {
    throw new Error('Headers must be of type Record<string, string>');
  }

  return token.sign({
    url,
    method: requestData.method,
    headers,
    body: requestData.body,
  });
}

let _onAuthenticationDenied = () => {};

export function onAuthenticationDenied(fun: () => void) {
  _onAuthenticationDenied = fun;
}

const AUTHENTICATION_DENIED_CODE = 401;

export async function fetchWithToken(url: string, requestInit: RequestInit) {
  const token = getToken();
  const requestData = {
    method: requestInit.method || 'get',
    headers: requestInit.headers,
    body: requestInit.body,
  };

  const signedPayload = signPayload({
    requestData,
    url,
    token,
  });

  const response = await fetch(url, signedPayload);
  if (response.status === AUTHENTICATION_DENIED_CODE) {
    _onAuthenticationDenied();
  }
  return response;
}
